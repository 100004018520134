<template>
  <div>
    <Navbar mode="light" />
    <div class="content">
      <div class="text-container">
        <figure class="img-container">
          <img
            src="@/assets/img/heroes-sections/giraffe-kruger-national-park-south-africa-travel-afrique.jpg"
            alt="Giraffe in Kruger National Park in South Africa"
            class="img"
          />
        </figure>
        <h1 class="title">{{ $t("error.title") }}</h1>
        <p class="paragraph">{{ $t("error.description") }}</p>
        <ButtonLink
          :text="`${$t('error.button')}`"
          route="/"
          class="px-5 center mt-8"
        />
        <div class="useful-links">
          <a :href="links.faq" target="_blank">
            {{ $t("error.links.link_2") }}
          </a>
          <a :href="links.terms" target="_blank"
            >{{ $t("error.links.link_3") }}
          </a>
          <a :href="links.refund" target="_blank">{{
            $t("error.links.link_4")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonLink from "@/components/buttons/ButtonLink";
import Navbar from "@/components/layout/Navbar";
export default {
  name: "Error",
  components: {
    ButtonLink,
    Navbar,
  },
  computed: {
    links() {
      if (localStorage.getItem("lang") === "fr")
        return {
          faq: "https://travelafrique.slite.com/api/s/note/ESVBB1FZ32BkzUuwNxgAnF/FAQ",
          terms:
            "https://travelafrique.slite.com/api/s/note/VLSdpNGdiYkZpkCHz2FznN/Termes-et-conditions",
          refund:
            "https://travelafrique.slite.com/api/s/note/XkHDdqYJHoZbUbgYaxPAcX/Politique-de-remboursement",
        };

      return {
        faq: "https://travelafrique.slite.com/p/note/Sf99TZDk4zDFtvAjxogB7k",
        terms: "https://travelafrique.slite.com/p/note/NVbBnvxFmtMi6F8W1am341",
        refund: "https://travelafrique.slite.com/p/note/4Nzxh4NKH5ufS43NnK1Z95",
      };
    },
  },
};
</script>
<style>
.img-container {
  @apply relative h-0 overflow-hidden rounded-md mb-8;
  padding-bottom: calc(0.108 / 0.192 * 100%);
}

.img {
  @apply w-full h-full absolute top-0 left-0 object-cover object-bottom;
}

.center {
  @apply relative left-1/2 transform -translate-x-1/2;
}

.overlay {
  @apply bg-black-base bg-opacity-50 h-100vh w-100vw;
}

.content {
  @apply w-full h-100vh bg-white flex items-center justify-center px-3;
}

.title {
  @apply text-2xl sm:text-4xl font-semibold text-center text-black-base;
}

.paragraph {
  @apply text-lg sm:text-xl text-center text-black-base;
}

.useful-links {
  @apply text-black-lightest underline flex justify-between mt-8 text-xs xxs:text-sm sm:text-base;
}

.useful-links a {
  @apply hover:text-grey-dark duration-300 transition-all ease-in-out text-center;
}

@media all and (max-height: 600px) {
  .content {
    @apply pt-20;
  }
}
</style>
